<template>
  <b-card>
    <div slot="header">
      <strong>Editar Regras de Grupos</strong>
    </div>
    <p><router-link :to="{ name: 'Listar Regras de Grupos' }">Editar Regras de Grupo</router-link></p>

    <form v-on:submit.prevent="addRegraGrupo">
      <div class="row">
        <div class="col-lg-6">

          <div class="form-group">
              <label name="dataCriacao"><strong>Grupo</strong></label>
              <select class="form-control" v-model="regraGrupo.grupo.id">
                <option value="">Selecione</option>
                <option value="1">exemplo grupo 1</option>
                <option value="2">exemplo grupo 2</option>
                <option value="3">exemplo grupo 3</option>
                <option value="4">exemplo grupo 4</option>
              </select>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="form-group">
              <label name="dataCriacao"><strong>Regra</strong></label>
              <select class="form-control" v-model="regraGrupo.regra.id">
                <option value="">Selecione</option>
                <option value="1">exemplo descricao 1</option>
                <option value="2">exemplo descricao 2</option>
                <option value="3">exemplo descricao 3</option>
                <option value="4">exemplo descricao 4</option>
              </select>
          </div>
        </div>
      </div>

      <button type="submit" class="btn btn-primary">Salvar</button>

    </form>

  </b-card>
</template>


<script>
export default {
  data () {
    return {
      regraGrupo: {
        id: 1,
        grupo: {id: 1, mnemonico: 'exemplo grupo 1'},
        regra: {id: 2, descricao: 'exemplo descricao 1'}
      }
    }
  },
  created: function () {
    this.verificaSessao()
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        this.carregar()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'error',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    addRegraGrupo: function () {
    },
    carregar: function () {
    }
  }
}
</script>
